// 以下内容为默认首页样式
.index_panel{
    .userinfo{
        background-image: url('../../images/userinfo-bg.png');
        background-size: cover;
        font-size: 18px;
    }

    .page-title.van-divider{
        color: $dark;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 1px;
        border-color: $gray-500;
        background-color: rgba($color: $gray-300, $alpha: .5);
    }
    .van-cascader__options{
        height: auto;
    }

    .van-form{
        .bottom-btn{
            position: fixed;
            bottom: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
}