.login_panel{
    height: 100vh;
    .logo{
        .van-image{
            background: linear-gradient(-45deg, #0e71b0, #ffffff, #229d83);
            background-size: 500% 500%;
            animation: moiveAnimation 5s infinite;
            border-radius: $border-mid;
        }
    }
    .van-form{
        .van-cell.van-field{
            .van-field__left-icon{
                .van-icon{
                    font-size: 24px;
                }
            }
        }
    }
}