.mu-nav-pills {
  .nav {
    display: flex;
    justify-content: center;

    .nav-item {
      position: relative;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 14px;

      .nav-link {
        padding: 0.5rem 1.5rem;
        color: $gray-600;
        border: 1px solid $gray-300;
        border-radius: 0;
        transition: $transition;

        &:hover,
        &:focus,
        &.active {
          color: $white;
          background-color: $primary;
          border-color: $primary;
        }

        i {
          margin-left: 5px;
        }
      }

      &.dropdown:hover > .nav-link {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }

      // 最后按钮的margin为0
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 992px) {
    .nav {
      justify-content: left;
    }
  }

  @media (max-width: 576px) {
    .dropdown-menu {
      position: static;
      float: none;
      margin-left: 0 !important;
    }
  }
}

.click-count {
  font-size: 12px;
}
