.slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 300px;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
}

.slideout-menu-left {
    left: 0;
}

.slideout-menu-right {
    right: 0;
}

.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    background-color: #fff;
    /* A background-color is required */
    min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}

.sidebar {
    display: none;

    .container {
        padding: 0;
    }

    .metismenu {
        min-height: 100vh;
    }

    .nav-link {
        color: $gray-300;
        padding-top: .75rem;
        padding-bottom: .75rem;

        &.disabled {
            color: $gray-500;
        }

        &.active {
            background-color: darken($gray-900, 5%);
        }
    }

    // 992px以上不显示
    @media (min-width: 992px) {
        background-color: $gray-900;
    }
}
