@import "node_modules/metismenujs/scss/metismenujs";

.mu-menu {
    display: none;

    @media (max-width: 992px) {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 48px;
        padding: 0;
        background-color: $white;
        height: 48px;
        z-index: 100;

        .nav-link {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 1;
            flex-grow: 1;
            color: $gray-900;
            border-top: 1px solid $border-color;
            border-left: 1px solid $border-color;
            height: 48px;


            i {
                margin-right: 0.25rem;
            }

            @media (max-width: 576px) {
                &:nth-child(1) {
                    border-left: 0;
                }

                &:last-child {
                    background-color: $primary;
                    color: $white;
                }
            }

        }
    }

    .nav-btn-link {
        flex: 1 1 auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        color: $white;
        background: $primary;
        padding: $spacer * 0.5 $spacer;
        text-decoration: none;
    }
}

// 移动端测导航
.sidebar-nav {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        background: #343a40;
    }

    .metismenu {
        display: flex;
        flex-direction: column;
        background: #212529;

        ul {
            a {
                padding: 10px 15px 10px 30px;
            }

            ul {
                a {
                    padding: 10px 15px 10px 45px;
                }
            }
        }

        &>li {
            display: flex;
            flex-direction: column;
            position: relative;

            &.mm-active {
                ul {
                    li.mm-active {
                        background: $primary;
                        /* 设置亮度值，范围：0-1 */
                        filter: brightness(1);
                    }
                }
            }
        }

        li+li {
            margin-top: 5px;
        }

        a {
            position: relative;
            display: block;
            padding: 13px 15px;
            color: $white;
            transition: $transition;
            text-decoration: none;
            opacity: .8;

            &:hover,
            &:focus,
            &:active,
            &>.mm-active>a {
                color: $white;
                text-decoration: none;
                background: $primary;
                opacity: 1;
            }
        }
    }
}
