// 提示弹窗 样式修改
.swal-modal {
    vertical-align: unset;
}

body {
    width: 100%;
    min-height: 100vh;
    font-family: "Microsoft YaHei", $font-family-base;

    // 开启追悼模式
    // filter: grayscale(95%);
}

.layout {
    display: flex;
    flex-direction: column;
}

#mu-panel {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    padding-top: 50px;
    transition: $transition;
    // padding-bottom: 44px;

    @media (min-width: 576px) {
        padding-top: 64px;
    }
}

.page-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: block;
    background-color: rgba($black, 1);
    transition: all 0.3s ease;
    opacity: 0;
    z-index: 1000;
}

.page-fill {
    flex: 1;
}

.page-banner {
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($black, 1);
        opacity: 0.5;
    }

    .page-banner-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
    }
}

.page-title {
    text-align: center;
}

.page-content {
    padding: 0;
    margin: 0;
    font-size: 15px;
    // text-indent: 2em;
    margin-bottom: 1rem;

    img {
        display: inherit;
        max-width: 100%;
        height: auto;
    }
}

// 页面内上下页切换按钮
.page-arrows {
    display: flex;
    background-color: $light;

    .page-arrow {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 120px;
        padding: 0.75rem 1rem;
        color: $gray-800;
        background-color: $light;
        font-size: 15px;
        overflow: hidden;
        transition: $transition;

        i {
            font-size: 17px;
        }

        &:hover {
            color: #ffffff;
            background-color: $primary;
            text-decoration: none;
        }

        &.disabled {
            color: $gray-500;
            background-color: $light;
            cursor: not-allowed;
            pointer-events: none;

            &:hover {
                color: $gray-500;
                background-color: $light;
            }
        }
    }
}

// common

/* layout,布局css样式 */
/* css reset , 使DOM元素在不同浏览器中的默认样式去除或者统一起来,以防止不同浏览器的解析不同而造成格式错乱 */
* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

body {
    font: 12px/1.5 tahoma, arial, Microsoft YaHei, sans-serif;
    /*字体默认12px,行间距1.5倍,以及一些常用字体*/
}

html {
    scroll-behavior: smooth;
}

/* ********************************* 定宽布局 ********************************* */
.w {
    width: 100%;
    min-width: 1200px;
    position: relative;
    overflow: hidden;
    font-family: Microsoft YaHei;

    .sw {
        width: 1200px;
        margin: 0 auto;
    }
}

.full-height {
    height: 100%;
}

/* ********************************* 通用flex布局 ********************************* */
/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-between */
.flex-row-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素space-around */
.flex-row-sa {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚中靠拢 */
.flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚左靠拢 */
.flex-row-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* flex容器样式，子元素横向排列，垂直方向居中，水平方向子元素聚右靠拢 */
.flex-row-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-between */
.flex-column-sb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素space-around */
.flex-column-sa {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚中靠拢 */
.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚上靠拢 */
.flex-column-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* flex容器样式，子元素纵向排列，水平方向居中，垂直方向子元素聚下靠拢 */
.flex-column-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

// 阻止flex子元素自适应伸缩
.flex-block {
    flex-shrink: 0;
    flex-grow: 0;
}

// flex子元素自适应伸缩
.flex-auto {
    flex-shrink: 1;
    flex-grow: 1;
}

// flex自动换行
.flex-wrap {
    flex-wrap: wrap;
}

// flex 垂直方向左对齐
.flex-align-start {
    align-items: flex-start;
}

// flex 垂直方向右对齐
.flex-align-end {
    align-items: flex-end;
}


/* ********************* 图片裁切（搭配flex系列样式食用更佳） ********************* */
/* 图片宽度铺满 */
.fimg {
    overflow: hidden;

    >img {
        width: 100%;
    }
}

/* 图片高度铺满 */
.fimg_d {
    overflow: hidden;

    >img {
        height: 100%;
    }
}

/* ********************************* 图片背景通用样式 ********************************* */
.back-row-c {
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.back-column-c {
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
}

.background-cross {
    background: -webkit-linear-gradient(left, $blue, $azure);
    background: -o-linear-gradient(left, $blue, $azure);
    background: -moz-linear-gradient(left, $blue, $azure);
    background: -ms-linear-gradient(left, $blue, $azure);
}

.background-ltd {
    background-color: $blue;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($blue), to(#94e593));
    background-image: -moz-linear-gradient($blue, $azure);
    background-image: -o-linear-gradient($blue, $azure);
    background-image: linear-gradient($blue, $azure);
}

/* ********************************* 通用行数限制 ********************************* */
.text-of-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-of-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

/* 编辑滚动条风格 */
.scroll-theme::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scroll-theme::-webkit-scrollbar-track {
    border-radius: 2px;
}

.scroll-theme::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 10px;
}

.scroll-theme::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.scroll-theme::-webkit-scrollbar-corner {
    background: #000;
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.2);
}

/* ********************************* 全局通用样式 ********************************* */
// 链接
.link {
    transition: $transition;
    cursor: pointer;

    &:hover {

        color: $primary;
    }
}

.link-primary {
    cursor: pointer;
    transition: $transition;
    color: $primary;

    &:hover {
        color: lighten($color: $primary, $amount: 20%);
    }
}

/* 手型 */
.pointer {
    cursor: pointer;
}

/* 隐藏类 */
.hide {
    display: none;
}

/* 完全平铺 */
.abs-full {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

/* 文本 */
.text-center {
    text-align: center;
}

/* 透明度 */
.opacity-limit {
    opacity: 0;
}

/* 定位 */
.relative {
    position: relative;
}

.absolute {
    position: relative;
}

.z-index-999 {
    z-index: 999;
}

/* 裁剪 */
.of-hidden {
    overflow: hidden;
}

// 元素禁用并置灰
.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
}

/* ********************************* 文字大小 ********************************* */
.text-sm {
    font-size: 12px !important;
}

.text-md {
    font-size: 14px !important;
}

.text-lg {
    font-size: 16px !important;
}

/* ********************************* 全局动画 ********************************* */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(200deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.rotate {
    transition: 0.5s;
    transform-origin: 50% 50%;
    animation: rotate 1s linear infinite;
}

[v-cloak] {
    display: none;
}