@font-face {
    font-family: "iconfont"; /* Project id 3016287 */
    src: url('//at.alicdn.com/t/font_3016287_cp8diq5sgdm.woff2?t=1639564790149') format('woff2'),
         url('//at.alicdn.com/t/font_3016287_cp8diq5sgdm.woff?t=1639564790149') format('woff'),
         url('//at.alicdn.com/t/font_3016287_cp8diq5sgdm.ttf?t=1639564790149') format('truetype');
  }

  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-dianhua:before {
    content: "\e62d";
  }

  .icon-dizhi:before {
    content: "\e62e";
  }

  .icon-wenjian:before {
    content: "\e633";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben:before {
    content: "\e601";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben1:before {
    content: "\e602";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben2:before {
    content: "\e603";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben3:before {
    content: "\e604";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben4:before {
    content: "\e605";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben5:before {
    content: "\e606";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben6:before {
    content: "\e607";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben7:before {
    content: "\e608";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben8:before {
    content: "\e609";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben9:before {
    content: "\e60a";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben10:before {
    content: "\e60b";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben11:before {
    content: "\e60c";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben12:before {
    content: "\e60d";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben13:before {
    content: "\e60e";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben14:before {
    content: "\e60f";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben15:before {
    content: "\e610";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben16:before {
    content: "\e611";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben17:before {
    content: "\e612";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben18:before {
    content: "\e613";
  }

  .icon-dilanxianxingiconyihuifu_huabanfuben19:before {
    content: "\e614";
  }
