.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    border: 2px solid transparent;
    background-color: rgba($white, 0.6);
    outline: none;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}

.swiper-pagination-bullet-active {
    background-color: transparent;
    border-color: $primary;
    width: 12px;
    height: 12px;
}

.mu-banner {
    .swiper-pagination {
        bottom: 1.5rem;
    }

    .swiper-pagination-bullet {
        width: 30px;
        height: 14px;
        border: 0;
        border-bottom: 4px solid $white;
        border-radius: 0;
        background: 0 0;
        opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }

    .swiper-slide {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $black;
            opacity: 0.5;
        }
    }

    .swiper-button-prev {
        left: 30px;
    }

    .swiper-button-next {
        right: 30px;
    }

    .swiper-slide-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
    }
}
