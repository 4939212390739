.mu-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 101;
    background-color: $white;
    border-bottom: 1px solid $border-color;

    // 导航logo
    .navbar-brand {
        padding: 5px 0;
        // order: 2;

        img {
            height: 40px;
            transition: $transition;
            @media (min-width: 576px) {
                height: 54px;
            }
        }
    }

    .navbar-nav {
        font-size: 16px;

        .nav-item {
            .nav-link {
                color: $gray-800;
                height: 64px;
                line-height: 64px;
                padding: 0 1.25rem;
                transition: $transition;

                i {
                    margin-left: 5px;
                }

                &:hover {
                    color: $primary;
                }
            }

            // 导航链接选中状态
            &.active {
                .nav-link {
                    color: $primary;
                }
            }
        }
    }

    .dropdown:hover {
        &>.nav-link {
            color: $primary;
        }
    }

    // 导航下拉菜单
    .dropdown-menu {
        margin-top: -0.5rem;

        .dropdown-item {
            font-size: 15px;
        }
    }

    // 联系电话
    // 992px以下会隐藏
    .navbar-phone {
        display: none;
        font-size: 17px;
        color: $gray-800;
        padding: 0 1.25rem;

        i {
            font-size: 15px;
            color: $gray-600;
            margin-right: 5px;
        }

        @media (min-width: 992px) {
            display: block;
        }
    }

    // 导航菜单展开按钮
    // 992px以下会显示
    .navbar-toggler {
        height: 50px;
        line-height: 50px;
        padding: 0;
        border: 0;
        outline: 0;
        order: 3;

        @media (min-width: 992px) {
            height: 64px;
            line-height: 64px;
        }
    }

    // 导航菜单
    // 992px以下会隐藏
    .navbar-menu {
        display: none;
        align-items: center;
        flex-direction: row;
        order: 2;

        @media (min-width: 992px) {
            display: flex;
        }
    }
}

// 导航搜索按钮
.navbar-search {
    order: 1;

    .navbar-search-button {
        display: block;
        color: $gray-600;
        background-color: transparent;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        padding: 0;
        border: 0;
        transition: $transition;
        outline: none;

        &:hover {
            color: $primary;
        }

        &:focus {
            outline: none;
        }

        @media (min-width: 992px) {
            height: 64px;
            line-height: 64px;
            padding: 0 1.25rem;
            order: 1;
        }
    }

    @media (min-width: 992px) {
        order: 3;
    }
}

// 导航搜索表单
.navbar-search-form {
    height: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: $gray-100;
    transition: $transition;
    overflow: hidden;

    &.show {
        top: 0;
    }

    .form-control {
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }

    @media (min-width: 992px) {
        height: 64px;
        line-height: 64px;
    }
}

// 导航搜索模态框
.navbar-search-modal {
    .modal-dialog {
        max-width: 100%;
        margin: 0;

        .modal-content {
            border: 0;
            border-radius: 0;

            .modal-body {
                padding: 0;
            }
        }
    }
}
