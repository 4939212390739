@import "node_modules/bootstrap/scss/bootstrap";

// Buttons
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

// Forms
.form-control:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    background-image: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

// Breadcrumbs
.breadcrumb {
    font-size: 14px;
}

.breadcrumb-item {
    +.breadcrumb-item {
        &::before {
            font-family: \5b8b\4f53;
        }
    }
}

// Dropdowns
.dropdown-menu {
    box-shadow: $box-shadow;
}
